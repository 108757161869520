import type { AxiosError, AxiosRequestConfig } from 'axios'
import axios from 'axios'
import * as toast from 'vue-toastification'
import type { ErrorMessage } from './../api/gen/model/error-message'
import { Configuration } from '~/api/gen'
import { getCurrentLanguage, i18n } from '~/modules/i18n'

const { useToast } = toast

/** Default config for axios instance */
const config: AxiosRequestConfig = {
  baseURL: import.meta.env.VITE_API_BASE_URL,
  withCredentials: false,
}

/** Creating the instance for axios */
const httpClient = axios.create(config)
const customErrorHandleHttpClient = axios.create(config)

/** Auth token interceptors */
const acceptLanguageInterceptor = (config: AxiosRequestConfig) => {
  if (!config.headers)
    config.headers = {}
  if (!config.headers['Accept-Language'])
    config.headers['Accept-Language'] = getCurrentLanguage()
  return config
}

/** logger interceptors */
const withCredentialsInterceptor = (config: AxiosRequestConfig) => {
  if (config?.url?.includes('media-access'))
    config.withCredentials = true

  return config
}

/** Adding the request interceptors */
httpClient.interceptors.request.use(acceptLanguageInterceptor)
httpClient.interceptors.request.use(withCredentialsInterceptor)

customErrorHandleHttpClient.interceptors.request.use(acceptLanguageInterceptor)
customErrorHandleHttpClient.interceptors.request.use(withCredentialsInterceptor)

const notAuthorizedAutoRetry = async (error: Error | AxiosError) => {
  if (axios.isAxiosError(error)) {
    const toast = useToast()
    const t = i18n.global.t

    const axiosError = error as AxiosError
    if (!!axiosError.response && axiosError.response?.status === 401) {
      const userStore = useUserStore()
      const originalRequest = error.config

      if (userStore.user.refreshToken && !originalRequest._retry) {
        originalRequest._retry = true
        if (await userStore.updateToken(60)) {
          originalRequest.headers!.Authorization = `Bearer ${userStore.user.token}`
          return httpClient(originalRequest)
        }
      }
      window.location.reload()
      toast.error(t('errors.unauthorized'))
      return Promise.resolve()
    }
  }
  return Promise.reject(error)
}
const generalErrorHandler = async (error: Error | AxiosError) => {
  const t = i18n.global.t
  const toast = useToast()
  if (axios.isAxiosError(error)) {
    const axiosError = error as AxiosError

    if (!!axiosError.response && axiosError.response?.status >= 400 && axiosError.response?.status <= 499 && axiosError.response?.status !== 401) {
      const errors = axiosError.response?.data.errors as Array<ErrorMessage>
      if (errors)
        toast.error(t('errors.business', { errors: errors.map((e: ErrorMessage) => e.errorMessage).join('\n') }))

      return Promise.reject(error)
    }

    toast.error(t('errors.oops'))
  }
  return Promise.reject(error)
}

httpClient.interceptors.response.use(response => response, generalErrorHandler)
httpClient.interceptors.response.use(response => response, notAuthorizedAutoRetry)
customErrorHandleHttpClient.interceptors.response.use(response => response, notAuthorizedAutoRetry)

const getConfiguration = () => {
  const userStore = useUserStore()
  if (userStore.user.token)
    return new Configuration({ basePath: 'api', accessToken: userStore.user.token })
  return new Configuration({ basePath: 'api' })
}

export { customErrorHandleHttpClient, httpClient, getConfiguration }

