<script setup lang="ts">
import * as toast from 'vue-toastification'

const { useToast } = toast

// https://github.com/vueuse/head
// you can use this to manipulate the document head in any components,
// they will be rendered correctly in the html results with vite-ssg
useHead({
  title: 'imma WebApp - Persönliche Videobotschaften, die bleiben. Für imma!',
  meta: [
    {
      name: 'theme-color',
      content: computed(() => isDark.value ? '#00aba9' : '#ffffff'),
    },
  ],
  link: [
    {
      rel: 'icon',
      type: 'image/png',
      href: computed(() => preferredDark.value ? '/favicon-32x32.png' : '/favicon-32x32.png'),
    },
  ],
})

const error = ref<string>()
const { t } = useI18n()

onErrorCaptured((e: Error) => {
  error.value = t('errors.oops')
  useToast().error(error.value, {
    timeout: 0,
  })
  return true
})
</script>

<template>
  <RouterView />
</template>
